<template>
  <div>
    <section class="section position-relative popUpFather">
      <div class="col-12 p-0">
        <div class="bg-white p-2">
          <b-tabs
            :value="$store.state.tabs.vueTechniqueTab"
            @changed="vueTechniqueTab = $store.state.tabs.vueTechniqueTab"
            @activate-tab="
              (newTabIndex) =>
                $store.dispatch('tabs/changeVueTechniqueTab', newTabIndex)
            "
            class="tab-solid tab-solid-primary mt-3"
          >
            <b-tab title="Capacités techniques">
              <div class="row mt-3 mb-2">
                <div class="col-12 d-flex flex-wrap justify-content-between">
                  <div class="my-auto mr-2">
                    <h4 class="card-title my-auto">
                      Liste des capacités techniques
                    </h4>
                  </div>
                  <DropDownCustom
                    :items="['Tableau', 'Graphique']"
                    @changeValue="
                      (value) =>
                        this.$store.dispatch(
                          'views/changeCapaciteTechniqueActiveView',
                          value
                        )
                    "
                    :value="$store.state.views.capaciteTechniqueActiveView"
                    class="mr-auto mb-2 mb-md-0"
                  />
                  <div class="mr-2 my-auto">
                    <b-form-input
                      v-model="searchQuery.name"
                      type="text"
                      placeholder="Recherche..."
                    ></b-form-input>
                  </div>
                  <CustomAddButton
                    v-if="isAdmin || isSuper || isArchitect"
                    @click="$router.push({ name: 'add-technical-capacity' })"
                    text="Créer capacité technique"
                  />
                </div>
              </div>
              <hr class="mt-0 bg-secondary" />

              <div
                class="row"
                v-if="$store.state.views.capaciteTechniqueActiveView == 0"
              >
                <div class="col-12">
                  <div class="card">
                    <!-- {{ capacites }} -->
                    <div class="card-body p-2">
                      <TechnicalCapacityTable
                        :loading="loadingData"
                        :items="filteredCapacites"
                        @showItem="
                          $router.push({ name: 'show-technical-capacity' })
                        "
                        @editItem="
                          $router.push({ name: 'edit-technical-capacity' })
                        "
                        @showTechnicalComponents="
                          showTachnicalComponentFunction
                        "
                        @showSousCapacite="showSousCapaciteFunction"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-else>
                <div class="col-12">
                  <graphic-view :capacites="filteredCapacites" />
                </div>
              </div>
            </b-tab>
            <b-tab title="Composants techniques">
              <div class="mt-3 flex-wrap justify-content-between">
                <div class="p-0 col-sm-auto mb-2">
                  <div class="p-0 col-sm-auto row m-0">
                    <div class="d-flex my-auto">
                      <div class="my-auto mr-2">
                        <h4 class="card-title my-auto">Liste des composants</h4>
                      </div>
                      <DropDownCustom
                        :items="['Tableau', 'Graphique']"
                        @changeValue="
                          (value) =>
                            this.$store.dispatch(
                              'views/changeComposantTechniqueActiveView',
                              value
                            )
                        "
                        :value="$store.state.views.composantTechniqueActiveView"
                      />
                    </div>
                    <div class="p-0 d-flex ml-auto mr-0 my-auto">
                      <div class="p-0 pl-2 my-auto" style="height: 44.22px">
                        <b-form-input
                          v-model="composantSearchQuery.name"
                          placeholder="Recherche un composant..."
                          class="end"
                        />
                      </div>
                      <div class="ml-2 my-auto">
                        <button
                          @click="$router.push({ name: 'add-composant' })"
                          type="button"
                          style="background-color: #198ae3"
                          class="
                            btn
                            my-auto
                            d-flex
                            text-white
                            align-items-center
                          "
                          v-if="isAdmin || isSuper || isArchitect"
                        >
                          <i class="mdi mdi-plus mr-2"></i>
                          Créer composant technique
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mt-0 bg-secondary" />

              <ComposantTable
                v-if="$store.state.views.composantTechniqueActiveView == 0"
                @showVersion="showVersionList"
                class="mt-3"
                :items="filteredComposants"
                :loading="composantLoadingData" />

              <composant-graphic-view
                :composants="filteredComposants"
                v-else /></b-tab
          ></b-tabs>
        </div>
      </div>
    </section>
    <div v-if="showTechnicalComponents" to="modals-xyz-548">
      <modal @close="showTechnicalComponents = !showTechnicalComponents">
        <technical-component-list :capacite="selectedCapacite" />
      </modal>
    </div>
    <div v-if="showSousCapacite" to="modals-xyz-548">
      <modal @close="showSousCapacite = !showSousCapacite">
        <sous-capacites-list :capacite="selectedCapacite" />
      </modal>
    </div>
    <!-- COMPOSANT -->
    <div v-if="showApps" to="modals-xyz-500">
      <modal @close="showApps = !showApps">
        <app-list :composant="selectedComposant" />
      </modal>
    </div>

    <div v-if="showVersion" to="modals-xyz-500">
      <modal @close="showVersion = !showVersion">
        <VersionList :composant="selectedComposant" />
      </modal>
    </div>
  </div>
</template>

<script>
import DropDownCustom from "@/components/custom/DropDown.vue";
import TechnicalCapacityTable from "./components/TechnicalCapacityTable.vue";
import Modal from "@/components/custom/Modal.vue";
import TechnicalComponentList from "./components/TechnicalComponentList.vue";
import SousCapacitesList from "./components/SousCapacitesList.vue";
import GraphicView from "./components/GraphicView.vue";
import { mapGetters } from "vuex";
import CustomAddButton from "@/components/custom/CustomAddButton";

// COMPOSANT
// import Modal from "@/components/custom/Modal.vue";
import AppList from "./composants/components/AppList.vue";
import ComposantTable from "./composants/components/ComposantTable.vue";
// import DropDownCustom from "@/components/custom/DropDown.vue";
import ComposantGraphicView from "./composants/components/GraphicView.vue";
import VersionList from "./composants/components/VersionList.vue";
import { uniqBy } from "lodash";
export default {
  components: {
    DropDownCustom,
    TechnicalCapacityTable,
    Modal,
    TechnicalComponentList,
    GraphicView,
    SousCapacitesList,
    CustomAddButton,
    // COMPOSANT
    ComposantTable,
    // SubModal,
    // Modal,
    AppList,
    ComposantGraphicView,
    VersionList,
  },

  data: () => ({
    selectedView: 0,
    showTechnicalComponents: false,
    selectedCapacite: {},
    showSousCapacite: false,
    searchQuery: {
      name: "",
    },
    loadingData: true,
    // COMPOSANT
    showApps: false,
    showVersion: false,
    showGraf: 0,
    composantLoadingData: true,
    selectedComposant: null,
    composantSearchQuery: {
      name: "",
    },
  }),
  methods: {
    showSousCapaciteFunction(item) {
      this.selectedCapacite = item;

      this.showSousCapacite = true;
    },
    // COMPOSANT
    onValueChange(value) {
      this.showGraf = value;
    },
    showVersionList(composant) {
      this.selectedComposant = composant;
      this.showVersion = true;
    },
    showTachnicalComponentFunction(capacite) {
      this.selectedCapacite = capacite;

      this.showTechnicalComponents = !this.showTechnicalComponents;
    },
  },
  created() {
    this.$store.dispatch("capaciteTechnique/fetchAllCapacites").then(() => {
      this.loadingData = false;
    });
    // COMPOSANT
    this.$store.dispatch("composant/fetchAllComposants").then(() => {
      this.composantLoadingData = false;
    });
  },
  computed: {
    ...mapGetters("capaciteTechnique", ["CAPACITES_TECHNIQUES"]),
    ...mapGetters("composant", ["COMPOSANTS"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),

    filteredCapacites() {
      return this.CAPACITES_TECHNIQUES.filter(
        (capacite) =>
          capacite.name
            .toLowerCase()
            .indexOf(this.searchQuery.name.toLowerCase()) > -1
      ).map((capacite) => ({
        ...capacite,
        composants: uniqBy(capacite.composants, "id"),
      }));
    },
    // COMPOSANT

    filteredComposants() {
      return this.COMPOSANTS.filter(
        (composant) =>
          composant.name
            .toLowerCase()
            .indexOf(this.composantSearchQuery.name.toLowerCase()) > -1
      );
    },
  },
};
</script>

<style scoped>
/* COMPOSANT */
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

/* .grid-btn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
} */

.input-search {
  padding: 1rem;
  height: 400px;
  border: 1px solid rgb(150, 145, 145);
  border-radius: 15px;
}
.input-search:focus {
  border: 1px solid rgb(204, 135, 253);
}

.end {
  align-self: center;
}

@media screen and (max-width: 760px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>
