<template>
  <div>
    <b-table
      responsive
      :items="items"
      :fields="technicalCapacity.tableFields"
      :busy="loading"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(composants)="data">
        <div class="text-center">
          <b-badge
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            v-if="data.value.length"
            pill
            @click="$emit('showTechnicalComponents', data.item)"
            >{{ data.value.length }}</b-badge
          >
          <b-badge
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            pill
            v-else
            >{{ data.value.length }}</b-badge
          >
        </div>
      </template>
      <template v-slot:cell(name)="data">
        <b-link
          :to="{
            name: 'show-technical-capacity',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
        >
          {{ data.value }}
        </b-link>
      </template>
      <template #cell(actions)="data">
        <table-actions
          :actions="isAdmin || isSuper || isArchitect ? ['delete'] : []"
          :editMode="data.item.hasOwnProperty('editMode')"
          @editItem="$emit('editItem')"
          @showItem="
            $router.push({
              name: 'show-technical-capacity',
              params: {
                id: data.item.id,
                slug: data.item.slug || 'abcde',
              },
            })
          "
          @deleteItem="deleteItem(data.item.id)"
        />
      </template>
      <template #cell(parent)="data">
        <b-link
          v-if="data.value"
          :to="{
            name: 'show-technical-capacity',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
          class="text-center"
        >
          {{ data.value.name }}
        </b-link>
        <div v-else>-</div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  components: { TableActions },
  props: {
    items: Array,
    loading: Boolean,
  },
  data: () => ({
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,

    sortable: true,
    technicalCapacity: {
      tableFields: [
        { key: "name", label: "Capacité technique", sortable: true },
        {
          key: "parent",
          label: "Capacité de rattachement",
          sortable: true,
        },
        {
          key: "niveau",
          label: "Niveau",
          sortable: true,
        },
        {
          key: "composants",
          label: "Composant technique",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    },
  }),
  computed: {
    // ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),

    rows() {
      return this.items.length;
    },
  },
  methods: {
    addFunction(item) {
      this.technicalCapacity.tableItems[item.itemIndex].function = item.value;
    },
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        text: "Cette opération est irréversible et entraînera la suppression de toutes les capacités attachées!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("capaciteTechnique/deleteCapacite", id)
            .then(() => {
              this.$store
                .dispatch("capaciteTechnique/fetchAllCapacites")
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
  },
};
</script>

<style></style>
