<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <!-- {{ composants }} -->
          <div class="card-body p-2">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              bordered
              striped
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(name)="data">
                <b-link
                  :to="{
                    name: 'show-composant',
                    params: {
                      id: data.item.id,
                      slug: data.item.slug || 'abcde',
                    },
                  }"
                  >{{ data.value }}</b-link
                >
              </template>

              <template v-slot:cell(capacites)="data">
                <b-badge
                  v-if="data.value.length"
                  style="
                    cursor: pointer;
                    background-color: #adb5bd;
                    border-color: #adb5bd;
                  "
                  @click="showCapacitesList(data.item)"
                  pill
                >
                  {{ data.value.length }}</b-badge
                >
                <b-badge
                  v-else
                  style="
                    cursor: pointer;
                    background-color: #adb5bd;
                    border-color: #adb5bd;
                  "
                  pill
                >
                  {{ data.value.length }}</b-badge
                >
              </template>

              <template v-slot:cell(archictecteTechnique)="data">
                <TableResponsable :responsable="data.value" />
              </template>

              <template v-slot:cell(taux)="data">
                <b-progress max="100" show-progress height="15px">
                  <b-progress-bar
                    :value="data.value"
                    :label="`${data.value}%`"
                    :style="`background-color: ${
                      data.value > 20 && data.value <= 70
                        ? '#f5f245'
                        : data.value > 70
                        ? '#23d92c'
                        : '#f5ba45'
                    }`"
                  ></b-progress-bar>
                </b-progress>
              </template>

              <template v-slot:cell(applications)="data">
                <div class="text-center">
                  <b-badge
                    v-if="data.value.length"
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    @click="showAppsList(data.item)"
                    pill
                  >
                    {{ data.value.length }}
                  </b-badge>
                  <b-badge
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    v-else
                    pill
                  >
                    {{ data.value.length }}
                  </b-badge>
                </div>
              </template>

              <template v-slot:cell(versions)="data">
                <div class="text-center">
                  <b-badge
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    v-if="data.value.length"
                    @click="showVersionList(data.item)"
                    pill
                  >
                    {{ data.value.length }}
                  </b-badge>
                  <b-badge
                    v-else
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                  >
                    {{ data.value.length }}
                  </b-badge>
                </div>
              </template>

              <template v-slot:cell(editeur)="data">
                <span> {{ data.value ? data.value.name : "-" }} </span>
              </template>
              <template v-slot:cell(businessLine)="data">
                <span> {{ data.value.length }} </span>
              </template>
              <template #cell(actions)="data">
                <table-actions
                  :actions="isAdmin || isSuper || isArchitect ? ['delete'] : []"
                  :editMode="data.item.hasOwnProperty('editMode')"
                  @editItem="$router.push({ name: 'edit-composant' })"
                  @showItem="
                    $router.push({
                      name: 'show-composant',
                      params: {
                        id: data.item.id,
                        slug: data.item.slug || 'abcde',
                      },
                    })
                  "
                  @deleteItem="deleteItem(data.item.id)"
                />
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- COMPOSANT -->
    <div v-if="showApps" to="modals-xyz-500">
      <modal @close="showApps = !showApps">
        <app-list :composant="selectedComposant" />
      </modal>
    </div>

    <div v-if="showVersion" to="modals-xyz-500">
      <modal @close="showVersion = !showVersion">
        <VersionList :composant="selectedComposant" />
      </modal>
    </div>

    <div v-if="showComposants" to="modals-xyz-500">
      <modal @close="showComposants = !showComposants">
        <CapacitesList :composant="selectedComposant" />
      </modal>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import AppList from "../components/AppList.vue";
import VersionList from "../components/VersionList.vue";
import CapacitesList from "../components/CapacitesList.vue";
import Modal from "@/components/custom/Modal.vue";
import { mapGetters } from "vuex";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableActions,
    TableResponsable,
    AppList,
    VersionList,
    CapacitesList,
    Modal,
  },
  data: function () {
    return {
      sortBy: "",
      perPage: 5,
      selectedComposant: null,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { key: "name", label: "Composant", sortable: true },
        { key: "versions", label: "Version", sortable: true },
        { key: "editeur", label: "Editeur", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        {
          key: "type",
          label: "Type composant",
          sortable: true,
        },
        { key: "capacites", label: "Capacités", sortable: false },
        { key: "taux", label: "Taux de remplissage", sortable: true },
        {
          key: "applications",
          label: "Applications supportées",
          sortable: false,
        },
        { key: "businessLine", label: "Ligne metier", sortable: false },
        {
          key: "archictecteTechnique",
          label: "Architecte technique",
          sortable: false,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      showApps: false,
      showVersion: false,
      showComposants: false,
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),

    rows() {
      return this.items.length;
    },
  },
  props: {
    items: Array,
    loading: Boolean,
  },
  methods: {
    showVersionList(composant) {
      this.selectedComposant = composant;
      this.showVersion = true;
    },
    showAppsList(composant) {
      this.selectedComposant = composant;
      this.showApps = true;
    },
    showCapacitesList(composant) {
      this.selectedComposant = composant;
      this.showComposants = true;
    },
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("composant/deleteComposant", id).then(() => {
            this.$store.dispatch("composant/fetchAllComposants");
          });

          Swal.fire("Supprimé!", "", "success");
        }
      });
    },
  },
};
</script>
