<template>
  <div class="tables">
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light"
            >Composant :
          </span>
          <span style="font-size: 22px">{{ composant.name }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">Liste des capacités :</h4>
        <hr />
      </div>
    </div>
    <b-table
      :items="this.COMPOSANT_CAPACITES.capacites"
      id="table-list"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      :busy="loading"
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(actions)="data">
        <table-actions
          :actions="['delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @editItem="editItem(data.item)"
          @deleteItem="deleteItem(data.item)"
          @cancelItem="cancelItem(data.item)"
          @confirmItem="confirmItem(data.item)"
        />
      </template>

      <template v-slot:cell(niveau)="data">
        <span>
          {{ data.value || 0 }}
        </span>
      </template>

      <template v-slot:cell(type)="data">
        <span>
          {{
            data.value === "CapaciteSi" ? "Capacité si" : "Capacité technique"
          }}
        </span>
      </template>
      <template v-slot:cell(name)="data">
        <b-link
          :to="{
            name:
              data.item.type === 'CapaciteSi'
                ? 'show-infosys-capacity'
                : 'show-technical-capacity',
            params: {
              id: data.item.id,
              slug: data.item.slug,
            },
          }"
        >
          {{ data.value || "-" }}
        </b-link>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    loading: true,
    fields: [
      { key: "type", label: "Type capacité", sortable: true },
      { key: "name", label: "Libellé capacité", sortable: true },
      { key: "niveau", label: "Niveau capacité", sortable: true },
    ],
  }),
  props: {
    composant: Object,
  },
  created() {
    this.$store
      .dispatch("composant/fetchComposantCapacites", this.composant.id)
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("composant", ["COMPOSANT_CAPACITES"]),

    rows: function () {
      return this.COMPOSANT_CAPACITES.capacites
        ? this.COMPOSANT_CAPACITES.capacites.length
        : 0;
    },
  },
};
</script>
