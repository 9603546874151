<template>
  <section class="tables">
    <div class="row">
      <div class="col-10" style="margin-top: -56px">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>
          <span style="font-size: 18px" class="font-weight-light"
            >Capacité technique :
          </span>
          <span style="font-size: 22px">{{ capacite.name }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">Liste des composants technique :</h4>
        <hr />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-table
          responsive
          :items="capacite.composants"
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          bordered
          striped
        >
          <template v-slot:cell(taux)="data">
            <b-progress max="100" show-progress height="15px">
              <b-progress-bar
                :value="data.value"
                :label="`${data.value}%`"
                :style="`background-color: ${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                }`"
              ></b-progress-bar>
            </b-progress>
          </template>

          <template v-slot:cell(patrimoine)="data">
            <b-link
              :to="{
                name: 'show-patrimony',
                params: {
                  id: data.value.id,
                  slug: data.value.slug || 'abcde',
                },
              }"
              v-if="data.value"
            >
              {{ data.value.name }}</b-link
            >
            <span v-else> -</span>
          </template>

          <template v-slot:cell(name)="data">
            <b-link
              :to="{
                name: 'show-composant',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
            >
              {{ data.value }}</b-link
            >
          </template>

          <template v-slot:cell(ref)="data">
            <b-link>CT-{{ data.item.id }}</b-link></template
          >
          <!-- 
          <template v-slot:cell(actions)="data">
            <table-actions :actions="data.value"></table-actions>
          </template> -->
        </b-table>
        <b-pagination
          aria-controls="table-list"
          align="right"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        >
        </b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
// import TableActions from "@/components/custom/TableActions.vue";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  // components: { TableActions },

  props: {
    capacite: Object,
  },
  data: function () {
    return {
      sortBy: "",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableItems: [
        {
          ref: "SA1",
          label: "CRM Saleforce",
          patrimony: "Application 1",
          status: "Etude",
          capacity_si: "SI - Distrib 1",
          po: "Didier Tibault",
          tech_lead: "Steeve EWORE",
          filling_rate: 70,
          // actions: ["edit", "delete"],
        },
      ],
      tableFields: [
        { key: "ref", label: "Réf", sortable: true },
        { key: "name", label: "Libellé", sortable: true },
        { key: "patrimoine", label: "Patrimoine", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "taux", label: "Taux de remplissage", sortable: true },
      ],
    };
  },
  computed: {
    rows() {
      return this.capacite.composants ? this.capacite.composants.length : 0;
    },
  },
};
</script>
