var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"tables"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10",staticStyle:{"margin-top":"-56px"}},[_c('p',{staticClass:"h2 m-0 text-secondary"},[_c('i',{class:_vm.$route.meta.iconClass,staticStyle:{"font-size":"22px"}}),_c('span',{staticClass:"font-weight-light",staticStyle:{"font-size":"18px"}},[_vm._v("Capacité technique : ")]),_c('span',{staticStyle:{"font-size":"22px"}},[_vm._v(_vm._s(_vm.capacite.name))])])])]),_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"responsive":"","items":_vm.capacite.composants,"fields":_vm.tableFields,"per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"bordered":"","striped":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(taux)",fn:function(data){return [_c('b-progress',{attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{style:(`background-color: ${
                data.value > 20 && data.value <= 70
                  ? '#f5f245'
                  : data.value > 70
                  ? '#23d92c'
                  : '#f5ba45'
              }`),attrs:{"value":data.value,"label":`${data.value}%`}})],1)]}},{key:"cell(patrimoine)",fn:function(data){return [(data.value)?_c('b-link',{attrs:{"to":{
              name: 'show-patrimony',
              params: {
                id: data.value.id,
                slug: data.value.slug || 'abcde',
              },
            }}},[_vm._v(" "+_vm._s(data.value.name))]):_c('span',[_vm._v(" -")])]}},{key:"cell(name)",fn:function(data){return [_c('b-link',{attrs:{"to":{
              name: 'show-composant',
              params: {
                id: data.item.id,
                slug: data.item.slug || 'abcde',
              },
            }}},[_vm._v(" "+_vm._s(data.value))])]}},{key:"cell(ref)",fn:function(data){return [_c('b-link',[_vm._v("CT-"+_vm._s(data.item.id))])]}}])}),_c('b-pagination',{attrs:{"aria-controls":"table-list","align":"right","total-rows":_vm.rows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h4',{staticClass:"card-title mt-4 mb-1"},[_vm._v("Liste des composants technique :")]),_c('hr')])])
}]

export { render, staticRenderFns }